import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, onMounted, onUpdated, reactive, ref, watch } from 'vue';
import videojs from 'video.js';
import { useInit, formatDate, encapsulateUrl } from '@/services/shared/utils';
import { PostMediaTypeEnum } from '@/services/features/group/group.model'; // Định nghĩa data type của component [props] bằng Typescript

var PostPreviewMediaComponentProps = function PostPreviewMediaComponentProps() {
  _classCallCheck(this, PostPreviewMediaComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PostPreviewMediaComponentData = function PostPreviewMediaComponentData() {
  _classCallCheck(this, PostPreviewMediaComponentData);
};

export default defineComponent({
  name: 'PostPreviewMediaComponent',
  props: {
    dataSource: {
      type: Object,
      default: null
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var videoPlayer = ref(null);
    var data = reactive({
      isLoading: false,
      dataSource: props.dataSource ? _objectSpread({}, props.dataSource) : null,
      player: null
    });
    watch(function () {
      return props.dataSource;
    }, function () {
      return data.dataSource = props.dataSource;
    }, {
      deep: true
    });

    var loadVideoPlayer = function loadVideoPlayer() {
      if (videoPlayer.value) {
        data.player = videojs(videoPlayer.value, {
          fill: true,
          muted: true,
          loop: true,
          autoplay: true,
          controls: false
        });
        data.player.src({
          type: 'video/mp4',
          src: data.dataSource.url
        });
      }

      if (data.player) {
        data.player.play();
      }
    };

    onMounted(function () {
      loadVideoPlayer();
    });
    onUpdated(function () {
      loadVideoPlayer();
    }); // Functions

    return {
      PostMediaTypeEnum: PostMediaTypeEnum,
      // Component reactive data
      videoPlayer: videoPlayer,
      data: data,
      // Functions
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl
    };
  }
});