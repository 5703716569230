import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.concat.js";
import { computed, defineComponent, reactive, watch } from 'vue';
import { getTypeFile, useInit } from '@/services/shared/utils';
import PostUserInGroupComponent from '@/components/features/group/PostUserInGroupComponent.vue';
import PostCreatorMediaLayout from '@/components/features/group/PostCreatorMediaLayout.vue';
import PostCreatorPollLayout from '@/components/features/group/PostCreatorPollLayout.vue';
import { CreateGroupPostModel, PostMediaTypeEnum } from '@/services/features/group/group.model';
import { isEmpty, find, cloneDeep } from 'lodash';

var PostCreatorDialogProps = function PostCreatorDialogProps() {
  _classCallCheck(this, PostCreatorDialogProps);
};

var PostCreatorDialogData = function PostCreatorDialogData() {
  _classCallCheck(this, PostCreatorDialogData);
};

export default defineComponent({
  name: 'PostCreatorDialog',
  components: {
    PostUserInGroupComponent: PostUserInGroupComponent,
    PostCreatorMediaLayout: PostCreatorMediaLayout,
    PostCreatorPollLayout: PostCreatorPollLayout
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      require: true
    },
    isPoll: {
      type: Boolean,
      default: false
    },
    poll: {
      type: Array,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    medias: {
      type: Array,
      default: null
    },
    documents: {
      type: Array,
      default: null
    },
    postStatus: {
      type: String,
      default: null
    }
  },
  emits: ['register'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      visible: props.visible,
      poll: cloneDeep(props.poll || []),
      description: props.description || '',
      medias: cloneDeep(props.medias) || [],
      documents: cloneDeep(props.documents) || [],
      isPoll: props.isPoll
    });
    watch(function () {
      return props.visible;
    }, function () {
      return data.visible = props.visible;
    });
    watch(function () {
      return props.isPoll;
    }, function () {
      return data.isPoll = props.isPoll;
    });
    var imageNo = computed(function () {
      return data.medias.filter(function (item) {
        return item.type.toUpperCase() === PostMediaTypeEnum.IMAGE;
      }).length;
    });
    var videoNo = computed(function () {
      return data.medias.filter(function (item) {
        return item.type.toUpperCase() === PostMediaTypeEnum.VIDEO;
      }).length;
    });
    var fileNo = computed(function () {
      return data.documents.length;
    });
    var hasMedia = computed(function () {
      return imageNo.value > 0 || videoNo.value > 0 || fileNo.value > 0;
    });
    var disabledRegister = computed(function () {
      if (isEmpty(data.description)) {
        return true;
      }

      if (data.isPoll) {
        if (isEmpty(data.poll)) {
          return true;
        }

        var errorPoll = find(data.poll, function (poll) {
          return isEmpty(poll.name) || isEmpty(poll.answers);
        });

        if (errorPoll) {
          return true;
        }
      }

      return false;
    });

    var onCloseIcon = function onCloseIcon(index) {
      data.medias.splice(index, 1);
    };

    var handleOnChange = function handleOnChange(file, _) {
      var videoSize = 20 * 1000 * 1024; //bytes

      var imageSize = 2 * 1000 * 1024;
      var docSize = 5 * 1000 * 1024;
      var blob = file.raw;
      var type = blob.type;

      if (getTypeFile(type) === PostMediaTypeEnum.IMAGE && (imageSize <= blob.size || imageNo.value >= 20)) {
        base.toast.error('Tối đa được chọn 20 ảnh, dung lượng mỗi ảnh tối đa là 2Mb');
        return;
      }

      if (getTypeFile(type) === PostMediaTypeEnum.VIDEO && (videoSize <= blob.size || videoNo.value >= 1)) {
        base.toast.error('Tối đa được chọn 1 video, dung lượng video tối đa là 20Mb');
        return;
      }

      if (getTypeFile(type) === PostMediaTypeEnum.FILE && (docSize <= blob.size || fileNo.value >= 3)) {
        base.toast.error('Tối đa được chọn 3 file, dung lượng file tối đa là 5Mb');
        return;
      }

      if (getTypeFile(type) === PostMediaTypeEnum.FILE) {
        data.documents.push({
          name: file.name,
          file: file.raw
        });
        return;
      }

      data.medias.push({
        type: getTypeFile(type),
        thumbnail: URL.createObjectURL(blob),
        url: URL.createObjectURL(blob),
        file: file.raw
      });
    };

    var onClosedDialog = function onClosedDialog() {
      data.description = '';
      data.medias = [];
      data.documents = [];
      data.poll = [];
      data.isPoll = false;
    };

    var clickUpload = function clickUpload(type) {
      if (type === PostMediaTypeEnum.IMAGE && imageNo.value >= 20) {
        base.toast.error('Tối đa được chọn 20 ảnh');
      }

      if (type === PostMediaTypeEnum.VIDEO && videoNo.value >= 1) {
        base.toast.error('Tối đa được chọn 1 video');
      }

      if (type === PostMediaTypeEnum.FILE && fileNo.value >= 3) {
        base.toast.error('Tối đa được chọn 3 file');
      }
    };

    var onCloseDocument = function onCloseDocument(index) {
      data.documents.splice(index, 1);
    };

    var onRegister = function onRegister() {
      if (data.isPoll) {
        if (isEmpty(data.poll)) {
          base.toast.error('Vui lòng nhập đầy đủ câu hỏi và ít nhất 1 lựa chọn tương ứng');
          return;
        }

        var errorPoll = find(data.poll, function (poll) {
          return isEmpty(poll.name) || isEmpty(poll.answers);
        });

        if (errorPoll) {
          base.toast.error('Vui lòng nhập đầy đủ câu hỏi và ít nhất 1 lựa chọn tương ứng');
          return;
        }
      }

      var model = new CreateGroupPostModel({
        description: data.description,
        medias: data.documents.concat(data.medias),
        groupId: props.group.id,
        poll: data.poll
      });
      emit('register', model);
    };

    var onClickPoll = function onClickPoll() {
      data.isPoll = !data.isPoll;
      data.poll = [];
      data.description = '';
    };

    var onChangePoll = function onChangePoll(poll) {
      data.poll = poll;
    };

    return {
      PostMediaTypeEnum: PostMediaTypeEnum,
      mq: base.mq,
      data: data,
      imageNo: imageNo,
      videoNo: videoNo,
      fileNo: fileNo,
      hasMedia: hasMedia,
      disabledRegister: disabledRegister,
      onCloseIcon: onCloseIcon,
      handleOnChange: handleOnChange,
      onClosedDialog: onClosedDialog,
      clickUpload: clickUpload,
      isEmpty: isEmpty,
      onCloseDocument: onCloseDocument,
      onRegister: onRegister,
      onClickPoll: onClickPoll,
      onChangePoll: onChangePoll
    };
  }
});