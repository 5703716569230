import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit, formatDate, encapsulateUrl, formatNumber } from '@/services/shared/utils';
import InvestorListItemAsideSkeleton from '@/components/skeleton/investor/InvestorListItemAsideSkeleton.vue'; // Định nghĩa data type của component [data] bằng Typescript

var InvestorSuggestAsideComponentData = function InvestorSuggestAsideComponentData() {
  _classCallCheck(this, InvestorSuggestAsideComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var InvestorSuggestAsideComponentProps = function InvestorSuggestAsideComponentProps() {
  _classCallCheck(this, InvestorSuggestAsideComponentProps);
};

export default defineComponent({
  name: 'InvestorSuggestAsideComponent',
  components: {
    InvestorListItemAsideSkeleton: InvestorListItemAsideSkeleton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    investorSuggest: {
      type: Array,
      default: null
    }
  },
  setup: function setup(props) {
    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: props.isLoading,
      items: props.investorSuggest
    });
    watch(function () {
      return props.isLoading;
    }, function () {
      return data.isLoading = props.isLoading;
    });
    watch(function () {
      return props.investorSuggest;
    }, function () {
      return data.items = props.investorSuggest;
    }); // Functions

    var goToInvestorDetailPage = function goToInvestorDetailPage(id) {
      return base.router.push({
        name: 'investor-detail',
        params: {
          id: id
        }
      });
    };

    var goToInvestorSuggestPage = function goToInvestorSuggestPage() {
      return base.router.push({
        name: 'investor-suggest'
      });
    };

    return {
      formatDate: formatDate,
      formatNumber: formatNumber,
      encapsulateUrl: encapsulateUrl,
      // Component reactive data
      data: data,
      // Functions
      goToInvestorDetailPage: goToInvestorDetailPage,
      goToInvestorSuggestPage: goToInvestorSuggestPage
    };
  }
});