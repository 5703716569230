import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-75395ffa"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "group-detail-my-post-component"
};
var _hoisted_2 = {
  class: "app-tab"
};
var _hoisted_3 = {
  key: 0,
  class: "app-loading"
};
var _hoisted_4 = {
  class: "group-detail-my-post-component__container",
  ref: "scrollWaitingComponent"
};
var _hoisted_5 = {
  key: 0,
  class: "app-loading"
};
var _hoisted_6 = {
  key: 0,
  class: "app-loading"
};
var _hoisted_7 = {
  class: "group-detail-my-post-component__container",
  ref: "scrollActiveComponent"
};
var _hoisted_8 = {
  key: 0,
  class: "app-loading"
};
var _hoisted_9 = {
  key: 0,
  class: "app-loading"
};
var _hoisted_10 = {
  class: "group-detail-my-post-component__container",
  ref: "scrollInActiveComponent"
};
var _hoisted_11 = {
  key: 0,
  class: "app-loading"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Tab = _resolveComponent("Tab");

  var _component_TabList = _resolveComponent("TabList");

  var _component_AppIconLoading = _resolveComponent("AppIconLoading");

  var _component_PostComponent = _resolveComponent("PostComponent");

  var _component_AppEmptyComponent = _resolveComponent("AppEmptyComponent");

  var _component_TabPanel = _resolveComponent("TabPanel");

  var _component_TabPanels = _resolveComponent("TabPanels");

  var _component_TabGroup = _resolveComponent("TabGroup");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_TabGroup, {
    defaultIndex: _ctx.data.tabDefault,
    onChange: _ctx.changedTab,
    as: "div",
    class: "group-detail-my-post-component__tabgroup"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_TabList, {
        as: "template"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Tab, {
            as: "template"
          }, {
            default: _withCtx(function (_ref) {
              var selected = _ref.selected;
              return [_createElementVNode("div", {
                class: _normalizeClass(["app-tab-btn", selected && 'active'])
              }, "Bài đang chờ", 2
              /* CLASS */
              )];
            }),
            _: 1
            /* STABLE */

          }), _createVNode(_component_Tab, {
            as: "template"
          }, {
            default: _withCtx(function (_ref2) {
              var selected = _ref2.selected;
              return [_createElementVNode("div", {
                class: _normalizeClass(["app-tab-btn", selected && 'active'])
              }, "Bài đã đăng", 2
              /* CLASS */
              )];
            }),
            _: 1
            /* STABLE */

          }), _createVNode(_component_Tab, {
            as: "template"
          }, {
            default: _withCtx(function (_ref3) {
              var selected = _ref3.selected;
              return [_createElementVNode("div", {
                class: _normalizeClass(["app-tab-btn", selected && 'active'])
              }, "Bài đã bị ẩn", 2
              /* CLASS */
              )];
            }),
            _: 1
            /* STABLE */

          })])];
        }),
        _: 1
        /* STABLE */

      }), _createVNode(_component_TabPanels, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_TabPanel, null, {
            default: _withCtx(function () {
              return [_ctx.data.waiting.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_AppIconLoading)])) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Có data "), _ctx.data.waiting.totalItems && _ctx.data.waiting.totalItems.length ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.waiting.totalItems, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [_createVNode(_component_PostComponent, {
                  modelValue: item,
                  type: _ctx.data.waiting.type
                }, null, 8
                /* PROPS */
                , ["modelValue", "type"])]);
              }), 128
              /* KEYED_FRAGMENT */
              ))], 512
              /* NEED_PATCH */
              ), _ctx.data.waiting.isLoadingMore ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_AppIconLoading)])) : _createCommentVNode("v-if", true)], 64
              /* STABLE_FRAGMENT */
              )) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Empty "), _createVNode(_component_AppEmptyComponent, {
                title: "Chưa có bài đăng nào để hiển thị"
              })], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))];
            }),
            _: 1
            /* STABLE */

          }), _createVNode(_component_TabPanel, null, {
            default: _withCtx(function () {
              return [_ctx.data.active.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_AppIconLoading)])) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Có data "), _ctx.data.active.totalItems && _ctx.data.active.totalItems.length ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.active.totalItems, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [_createVNode(_component_PostComponent, {
                  modelValue: item,
                  type: _ctx.data.active.type
                }, null, 8
                /* PROPS */
                , ["modelValue", "type"])]);
              }), 128
              /* KEYED_FRAGMENT */
              ))], 512
              /* NEED_PATCH */
              ), _ctx.data.active.isLoadingMore ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_AppIconLoading)])) : _createCommentVNode("v-if", true)], 64
              /* STABLE_FRAGMENT */
              )) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Empty "), _createVNode(_component_AppEmptyComponent, {
                title: "Chưa có bài đăng nào để hiển thị"
              })], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))];
            }),
            _: 1
            /* STABLE */

          }), _createVNode(_component_TabPanel, null, {
            default: _withCtx(function () {
              return [_ctx.data.inactive.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_AppIconLoading)])) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Có data "), _ctx.data.inactive.totalItems && _ctx.data.inactive.totalItems.length ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.inactive.totalItems, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [_createVNode(_component_PostComponent, {
                  modelValue: item,
                  type: _ctx.data.inactive.type
                }, null, 8
                /* PROPS */
                , ["modelValue", "type"])]);
              }), 128
              /* KEYED_FRAGMENT */
              ))], 512
              /* NEED_PATCH */
              ), _ctx.data.inactive.isLoadingMore ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_AppIconLoading)])) : _createCommentVNode("v-if", true)], 64
              /* STABLE_FRAGMENT */
              )) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createCommentVNode(" Empty "), _createVNode(_component_AppEmptyComponent, {
                title: "Chưa có bài đăng nào để hiển thị"
              })], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))], 2112
              /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
              ))];
            }),
            _: 1
            /* STABLE */

          })];
        }),
        _: 1
        /* STABLE */

      })];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["defaultIndex", "onChange"])]);
}