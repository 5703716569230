import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-16476ef6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "investor-detail-page"
};
var _hoisted_2 = {
  class: "container-xl investor-detail-page__container"
};
var _hoisted_3 = {
  class: "app-section-content"
};
var _hoisted_4 = {
  key: 0,
  class: "investor-detail-page__list"
};
var _hoisted_5 = {
  key: 1,
  class: "investor-detail-page__list"
};
var _hoisted_6 = {
  key: 2,
  class: "app-pagination"
};
var _hoisted_7 = {
  class: "app-section-aside"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_InvestorHeaderComponent = _resolveComponent("InvestorHeaderComponent");

  var _component_InvestorListItemAsideSkeleton = _resolveComponent("InvestorListItemAsideSkeleton");

  var _component_ProjectListItemComponent = _resolveComponent("ProjectListItemComponent");

  var _component_AppEmptyComponent = _resolveComponent("AppEmptyComponent");

  var _component_el_pagination = _resolveComponent("el-pagination");

  var _component_InvestorDetailAsideComponent = _resolveComponent("InvestorDetailAsideComponent");

  var _component_InvestorDetailAsideSkeleton = _resolveComponent("InvestorDetailAsideSkeleton");

  var _component_ProjectOrderDialog = _resolveComponent("ProjectOrderDialog");

  var _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createCommentVNode(" <DialogDownloadComponent /> "), _createVNode(_component_InvestorHeaderComponent, {
    investor: _ctx.data.investor,
    activeTab: _ctx.data.activeTab,
    onOnActiveTabChange: _ctx.switchTab
  }, null, 8
  /* PROPS */
  , ["investor", "activeTab", "onOnActiveTabChange"]), _createElementVNode("div", _hoisted_2, [_createCommentVNode(" Content bên trái "), _withDirectives(_createElementVNode("div", _hoisted_3, [_createCommentVNode(" Loading skeleton items "), _ctx.data.listProject.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(+_ctx.data.listProject.queryParams.pageSize, function (index) {
    return _openBlock(), _createBlock(_component_InvestorListItemAsideSkeleton, {
      key: index
    });
  }), 128
  /* KEYED_FRAGMENT */
  ))])) : _createCommentVNode("v-if", true), _createCommentVNode(" Real items "), !_ctx.data.listProject.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_ctx.data.listProject.paging && _ctx.data.listProject.paging.total ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.data.listProject.totalItems, function (item) {
    return _openBlock(), _createBlock(_component_ProjectListItemComponent, {
      key: item.id,
      project: item,
      onOnOpenDialog: _ctx.handleOpenDialog
    }, null, 8
    /* PROPS */
    , ["project", "onOnOpenDialog"]);
  }), 128
  /* KEYED_FRAGMENT */
  )) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createCommentVNode(" Empty "), _createVNode(_component_AppEmptyComponent, {
    title: "Đang cập nhật thông tin dự án"
  })], 2112
  /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
  ))])) : _createCommentVNode("v-if", true), _createCommentVNode(" Pagination "), _ctx.data.listProject.paging && _ctx.data.listProject.paging.total ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_pagination, {
    layout: "prev, pager, next",
    "current-page": +_ctx.data.listProject.queryParams.page,
    "page-size": +_ctx.data.listProject.queryParams.pageSize,
    total: _ctx.data.listProject.paging.total,
    onCurrentChange: _ctx.handlePaginationPageChange
  }, null, 8
  /* PROPS */
  , ["current-page", "page-size", "total", "onCurrentChange"])])) : _createCommentVNode("v-if", true)], 512
  /* NEED_PATCH */
  ), [[_directive_loading, _ctx.data.listProject.isLoading]]), _createCommentVNode(" Aside bên phải "), _createElementVNode("div", _hoisted_7, [_ctx.data.investor ? (_openBlock(), _createBlock(_component_InvestorDetailAsideComponent, {
    key: 0,
    investor: _ctx.data.investor
  }, null, 8
  /* PROPS */
  , ["investor"])) : (_openBlock(), _createBlock(_component_InvestorDetailAsideSkeleton, {
    key: 1
  }))])])]), _createVNode(_component_ProjectOrderDialog, {
    visible: _ctx.data.dialogDownloadVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.data.dialogDownloadVisible = $event;
    })
  }, null, 8
  /* PROPS */
  , ["visible"])], 64
  /* STABLE_FRAGMENT */
  );
}