import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { InvestorModel } from '@/services/features/investor/investor.model'; // Định nghĩa data type của component [props] bằng Typescript

var InvestorHeaderComponentProps = function InvestorHeaderComponentProps() {
  _classCallCheck(this, InvestorHeaderComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var InvestorDetailAsideComponentData = function InvestorDetailAsideComponentData() {
  _classCallCheck(this, InvestorDetailAsideComponentData);
};

export default defineComponent({
  name: 'InvestorDetailAsideComponent',
  props: {
    investor: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      dataSource: props.investor || new InvestorModel()
    });
    watch(function () {
      return props.investor;
    }, function (newValue, oldValue) {
      data.dataSource = newValue || new InvestorModel();
    }); // Function

    return {
      // Component reactive data
      data: data
    };
  }
});