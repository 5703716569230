import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2059d5bc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "history-call-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ag_grid_vue = _resolveComponent("ag-grid-vue");

  var _component_HistoryCallLeadSkeleton = _resolveComponent("HistoryCallLeadSkeleton");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_ag_grid_vue, {
    class: "ag-theme-alpine app-ag-theme-alpine",
    style: {
      "width": "100%",
      "height": "100%"
    },
    domLayout: 'autoHeight',
    columnDefs: _ctx.columnDefs.value,
    rowData: _ctx.data.rowData,
    gridOptions: _ctx.gridOptions,
    onGridReady: _ctx.onGridReady
  }, null, 8
  /* PROPS */
  , ["columnDefs", "rowData", "gridOptions", "onGridReady"]), [[_vShow, !_ctx.data.isLoading]]), _ctx.data.isLoading ? (_openBlock(), _createBlock(_component_HistoryCallLeadSkeleton, {
    key: 0
  })) : _createCommentVNode("v-if", true)]);
}