import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.function.name.js";
// Composition API
import { defineComponent, reactive, onMounted, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { debounce } from 'lodash'; // Định nghĩa data type của component [props] bằng Typescript

var GroupListItemComponentProps = function GroupListItemComponentProps() {
  _classCallCheck(this, GroupListItemComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var GroupHeaderComponentData = function GroupHeaderComponentData() {
  _classCallCheck(this, GroupHeaderComponentData);
};

export default defineComponent({
  name: 'GroupHeaderComponent',
  props: {
    searchText: {
      type: String,
      default: null
    }
  },
  emits: ['onSearch'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _a; // Setup Default


    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      isHomePage: false,
      searchText: props.searchText || '',
      breadcrumb: []
    });
    watch(function () {
      return props.searchText;
    }, function (newValue, oldValue) {
      data.searchText = newValue || '';
    }); // Functions

    var onSearch = debounce(function () {
      emit('onSearch', data.searchText);
    }, 300); // Life circle

    onMounted(function () {
      data.isHomePage = base.route.name === 'group';
    });
    return {
      // Base
      breadcrumb: ((_a = base.route.meta) === null || _a === void 0 ? void 0 : _a.breadcrumb) || [],
      // Component reactive data
      data: data,
      // Functions
      onSearch: onSearch
    };
  }
});