import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7f2651af"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "post-preview-media-component"
};
var _hoisted_2 = {
  key: 1,
  class: "post-preview-media-component__video-player"
};
var _hoisted_3 = {
  ref: "videoPlayer",
  class: "video-js vjs-theme-forest"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.data.dataSource ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.data.dataSource.type.toUpperCase() === _ctx.PostMediaTypeEnum.IMAGE ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_ctx.isThumbnail ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "post-preview-media-component__background",
    style: _normalizeStyle(_ctx.data.dataSource.thumbnail && {
      'background-image': 'url(' + _ctx.encapsulateUrl(_ctx.data.dataSource.thumbnail) + ')'
    })
  }, null, 4
  /* STYLE */
  )) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "post-preview-media-component__background",
    style: _normalizeStyle(_ctx.data.dataSource.url && {
      'background-image': 'url(' + _ctx.encapsulateUrl(_ctx.data.dataSource.url) + ')'
    })
  }, null, 4
  /* STYLE */
  ))], 2112
  /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
  )) : _ctx.data.dataSource.type.toUpperCase() === _ctx.PostMediaTypeEnum.VIDEO ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("video", _hoisted_3, null, 512
  /* NEED_PATCH */
  )])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}