import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, onMounted, onUnmounted, onUpdated, reactive, ref, watch } from 'vue';
import videojs from 'video.js';
import { useInit, formatDate, encapsulateUrl, getFileName } from '@/services/shared/utils';
import { PostMediaTypeEnum } from '@/services/features/group/group.model';
import PropskillViewDocumentComponent from '@/components/features/elearning/PropskillViewDocumentComponent.vue'; // Định nghĩa data type của component [props] bằng Typescript

var PostMediaComponentProps = function PostMediaComponentProps() {
  _classCallCheck(this, PostMediaComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PostMediaComponentData = function PostMediaComponentData() {
  _classCallCheck(this, PostMediaComponentData);
};

export default defineComponent({
  name: 'PostMediaComponent',
  components: {
    PropskillViewDocumentComponent: PropskillViewDocumentComponent
  },
  props: {
    dataSource: {
      type: Object,
      default: null
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var videoPlayer = ref(null);
    var data = reactive({
      isLoading: false,
      dataSource: props.dataSource ? _objectSpread({}, props.dataSource) : null,
      player: null,
      isVisibleYoutubeVideo: false,
      dialogView: false,
      url: ''
    });
    watch(function () {
      return props.dataSource;
    }, function () {
      return data.dataSource = props.dataSource;
    }, {
      deep: true
    });

    var loadVideoPlayer = function loadVideoPlayer() {
      if (videoPlayer.value) {
        data.player = videojs(videoPlayer.value, {
          fill: true,
          controls: true,
          preload: 'auto'
        }, function () {
          data.player.src({
            type: 'video/mp4',
            src: data.dataSource.url
          });
        });
        var reloadTime = 0;
        data.player.on('error', function () {
          if (reloadTime >= 3) {
            return;
          }

          reloadTime++;
          data.player.addClass('vjs-waiting');
          data.player.error(null);
          setTimeout(function () {
            data.player.load();
          }, 3000);
        });
      }
    };

    var youtubeVisibilityChanged = function youtubeVisibilityChanged(visibility) {
      if (visibility) {
        data.isVisibleYoutubeVideo = true;
      }
    };

    onMounted(function () {
      return loadVideoPlayer();
    });
    onUpdated(function () {
      return loadVideoPlayer();
    });
    onUnmounted(function () {
      if (data.player) {
        data.player.dispose();
      }
    });

    var onClick = function onClick(e) {
      emit('click', e);
    };

    var onCloseDialog = function onCloseDialog() {
      data.dialogView = false;
      data.url = '';
    };

    var viewDocument = function viewDocument(url) {
      data.dialogView = true;
      data.url = url;
    }; // Functions


    return {
      PostMediaTypeEnum: PostMediaTypeEnum,
      // Component reactive data
      videoPlayer: videoPlayer,
      data: data,
      // Functions
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl,
      onClick: onClick,
      youtubeVisibilityChanged: youtubeVisibilityChanged,
      getFileName: getFileName,
      onCloseDialog: onCloseDialog,
      viewDocument: viewDocument
    };
  }
});