import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { cloneDeep } from 'lodash';
import linkifyHtml from 'linkify-html';
import PostMediaComponent from '@/components/features/group/PostMediaComponent.vue';
import AppCarouselComponent from '@/components/features/group/AppCarouselComponent.vue';
import UserDetailDialog from '@/components/features/user-profile/UserDetailDialog.vue';
import UserAvatarComponent from '@/components/features/user-profile/UserAvatarComponent.vue';
import { useInit, formatDate, encapsulateUrl, downloadFile } from '@/services/shared/utils';
import { getTimeSince } from '@/services/features/group/group.service';
import { GroupPostModel, PostMediaTypeEnum } from '@/services/features/group/group.model'; // Định nghĩa data type của component [props] bằng Typescript

var PostHistoryComponentProps = function PostHistoryComponentProps() {
  _classCallCheck(this, PostHistoryComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PostHistoryComponentData = function PostHistoryComponentData() {
  _classCallCheck(this, PostHistoryComponentData);
}; // Định nghĩa enum thứ tự hiển thị media bằng Typescript


var PostMediaDisplayOrder;

(function (PostMediaDisplayOrder) {
  PostMediaDisplayOrder[PostMediaDisplayOrder["First"] = 0] = "First";
  PostMediaDisplayOrder[PostMediaDisplayOrder["Second"] = 1] = "Second";
  PostMediaDisplayOrder[PostMediaDisplayOrder["Third"] = 2] = "Third";
  PostMediaDisplayOrder[PostMediaDisplayOrder["Fourth"] = 3] = "Fourth";
  PostMediaDisplayOrder[PostMediaDisplayOrder["More"] = 4] = "More";
})(PostMediaDisplayOrder || (PostMediaDisplayOrder = {}));

export default defineComponent({
  name: 'PostHistoryComponent',
  components: {
    PostMediaComponent: PostMediaComponent,
    AppCarouselComponent: AppCarouselComponent,
    UserDetailDialog: UserDetailDialog,
    UserAvatarComponent: UserAvatarComponent
  },
  props: {
    modelValue: {
      type: GroupPostModel,
      default: null,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      dataSource: _objectSpread({}, props.modelValue),
      medias: null,
      files: null,
      carousel: {
        visible: false,
        items: [],
        selected: 0
      },
      dialogUserDetail: {
        visible: false,
        model: props.modelValue ? props.modelValue.user : null
      }
    });
    watch(function () {
      return props.modelValue;
    }, function (newValue, oldValue) {
      data.dataSource = newValue || null;
    }); // Tách file image/video ra từ mảng [medias]

    if (data.dataSource && data.dataSource.medias) {
      var newMedias = cloneDeep(data.dataSource.medias);
      newMedias = newMedias.filter(function (item) {
        return item.type !== PostMediaTypeEnum.FILE;
      }); // Gom 2 item type THUMBNAIL và VIDEO lại làm 1

      var mediaVideoIndex = newMedias.findIndex(function (item) {
        return item.type === PostMediaTypeEnum.VIDEO;
      });

      if (mediaVideoIndex > -1) {
        var mediaThumbnail = newMedias.find(function (item) {
          return item.type === PostMediaTypeEnum.THUMBNAIL;
        });
        newMedias[mediaVideoIndex].thumbnail = mediaThumbnail ? mediaThumbnail.url : null;
        newMedias = newMedias.filter(function (item) {
          return item.type !== PostMediaTypeEnum.THUMBNAIL;
        });
      }

      data.medias = newMedias;
    } // Tách file tài liệu ra từ mảng [medias]


    if (data.dataSource && data.dataSource.medias) {
      var newFiles = cloneDeep(data.dataSource.medias);
      newFiles = newFiles.filter(function (item) {
        return item.type === PostMediaTypeEnum.FILE;
      }).map(function (item) {
        if (item.url) {
          var paths = item.url.split('/');
          item.fileName = paths[paths.length - 1];
        }

        return item;
      });
      data.files = newFiles;
    } // Functions


    var goToGroupDetailPage = function goToGroupDetailPage(id) {
      return base.router.push({
        name: 'group-detail',
        params: {
          id: id
        }
      });
    }; // Hiển thị Carousel ảnh


    var onClickPostMedia = function onClickPostMedia(medias, index) {
      data.carousel.visible = true;
      data.carousel.items = medias;

      if (index === PostMediaDisplayOrder.More) {
        data.carousel.selected = PostMediaDisplayOrder.First;
      }

      if (index < PostMediaDisplayOrder.More) {
        data.carousel.selected = index;
      }
    }; // Đóng carousel


    var onCloseCarousel = function onCloseCarousel() {
      data.carousel.visible = false;
    }; // Ẩn/hiện dialog user


    var handleDialogUserVisible = function handleDialogUserVisible(isVisible) {
      data.dialogUserDetail.visible = isVisible;
    };

    return {
      linkifyHtml: linkifyHtml,
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl,
      // Component reactive data
      data: data,
      // Functions
      getTimeSince: getTimeSince,
      goToGroupDetailPage: goToGroupDetailPage,
      downloadFile: downloadFile,
      onClickPostMedia: onClickPostMedia,
      onCloseCarousel: onCloseCarousel,
      handleDialogUserVisible: handleDialogUserVisible
    };
  }
});