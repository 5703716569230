import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { useInit } from '@/services/shared/utils';
import { defineComponent } from 'vue';

var ProjectOrderDialogProps = function ProjectOrderDialogProps() {
  _classCallCheck(this, ProjectOrderDialogProps);
};

export default defineComponent({
  name: 'ProjectOrderDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base;

    var onClosedDialog = function onClosedDialog() {
      emit('update:visible', false);
    };

    return {
      onClosedDialog: onClosedDialog
    };
  }
});