import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.splice.js";
import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import { PostAnswerModel, PostPollModel, GroupMemberRequestStatusEnum } from '@/services/features/group/group.model';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { isEmpty } from 'lodash';

var PostCreatorPollLayoutProps = function PostCreatorPollLayoutProps() {
  _classCallCheck(this, PostCreatorPollLayoutProps);
};

var PostCreatorPollLayoutData = function PostCreatorPollLayoutData() {
  _classCallCheck(this, PostCreatorPollLayoutData);
};

export default defineComponent({
  name: 'PostCreatorPollLayout',
  components: {
    Popover: Popover,
    PopoverButton: PopoverButton,
    PopoverPanel: PopoverPanel
  },
  props: {
    poll: {
      type: Array,
      default: null
    },
    postStatus: {
      type: String,
      default: null
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      poll: props.poll && !isEmpty(props.poll) ? props.poll : [new PostPollModel({
        name: '',
        answers: []
      })]
    });

    var addAnswer = function addAnswer(event, index) {
      if (!event || !event.target) {
        return;
      }

      var value = event.target.value;
      event.target.value = '';

      if (!data.poll[index]) {
        return;
      }

      data.poll[index].answers.push(new PostAnswerModel({
        name: value
      }));
      emit('poll', data.poll);
    };

    var closeAnswer = function closeAnswer(parentIndex, index) {
      if (!data.poll[parentIndex]) {
        return;
      }

      if (!data.poll[parentIndex].answers[index]) {
        return;
      }

      data.poll[parentIndex].answers.splice(index, 1);
      emit('poll', data.poll);
    };

    var addQuestion = function addQuestion() {
      data.poll.push(new PostPollModel({
        name: '',
        answers: []
      }));
      emit('poll', data.poll);
    };

    var closeQuestion = function closeQuestion(index) {
      if (!data.poll[index]) {
        return;
      }

      data.poll.splice(index, 1);
      emit('poll', data.poll);
    };

    return {
      data: data,
      GroupMemberRequestStatusEnum: GroupMemberRequestStatusEnum,
      addAnswer: addAnswer,
      closeAnswer: closeAnswer,
      addQuestion: addQuestion,
      closeQuestion: closeQuestion
    };
  }
});