import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit, formatDate, encapsulateUrl } from '@/services/shared/utils';
import { getTimeSince } from '@/services/features/group/group.service';
import GroupListItemAsideSkeleton from '@/components/skeleton/group/GroupListItemAsideSkeleton.vue'; // Định nghĩa data type của component [data] bằng Typescript

var GroupParticipatedAsideComponentData = function GroupParticipatedAsideComponentData() {
  _classCallCheck(this, GroupParticipatedAsideComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var GroupParticipatedAsideComponentProps = function GroupParticipatedAsideComponentProps() {
  _classCallCheck(this, GroupParticipatedAsideComponentProps);
};

export default defineComponent({
  name: 'GroupParticipatedAsideComponent',
  components: {
    GroupListItemAsideSkeleton: GroupListItemAsideSkeleton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    groupParticipated: {
      type: Array,
      default: null
    }
  },
  setup: function setup(props) {
    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: props.isLoading,
      items: props.groupParticipated
    });
    watch(function () {
      return props.isLoading;
    }, function () {
      return data.isLoading = props.isLoading;
    });
    watch(function () {
      return props.groupParticipated;
    }, function () {
      return data.items = props.groupParticipated;
    }); // Functions

    var goToGroupDetailPage = function goToGroupDetailPage(id) {
      return base.router.push({
        name: 'group-detail',
        params: {
          id: id
        }
      });
    };

    var goToGroupParticipatedPage = function goToGroupParticipatedPage() {
      return base.router.push({
        name: 'group-participated'
      });
    };

    return {
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl,
      // Component reactive data
      data: data,
      // Functions
      getTimeSince: getTimeSince,
      goToGroupDetailPage: goToGroupDetailPage,
      goToGroupParticipatedPage: goToGroupParticipatedPage
    };
  }
});