import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive, watch } from 'vue';
import { encapsulateUrl, formatNumber, useInit } from '@/services/shared/utils';
import { getTimeSince } from '@/services/features/group/group.service';
import { InvestorModel } from '@/services/features/investor/investor.model'; // Định nghĩa section của component bằng Typescript

var InvestorDetailPageSection;

(function (InvestorDetailPageSection) {
  InvestorDetailPageSection["ALL"] = "all";
  InvestorDetailPageSection["CAN_HO"] = "can-ho";
  InvestorDetailPageSection["DAT_NEN"] = "dat-nen";
  InvestorDetailPageSection["RESORT"] = "resort";
  InvestorDetailPageSection["BIET_THU"] = "biet-thu";
  InvestorDetailPageSection["SHOPHOUSE"] = "shophouse";
})(InvestorDetailPageSection || (InvestorDetailPageSection = {})); // Định nghĩa data type của component [data] bằng Typescript


var InvestorHeaderComponentData = function InvestorHeaderComponentData() {
  _classCallCheck(this, InvestorHeaderComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var InvestorHeaderComponentProps = function InvestorHeaderComponentProps() {
  _classCallCheck(this, InvestorHeaderComponentProps);
};

export default defineComponent({
  name: 'InvestorHeaderComponent',
  props: {
    investor: {
      type: Object,
      default: null
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  emits: ['onActiveTabChange'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base; // Component reactive data


    var data = reactive({
      isLoading: false,
      investor: props.investor || new InvestorModel(),
      activeTab: props.activeTab,
      investorId: base.route.params.id
    });
    watch(function () {
      return props.investor;
    }, function (newValue, oldValue) {
      data.investor = newValue || new InvestorModel();
    });
    watch(function () {
      return props.activeTab;
    }, function (newValue, oldValue) {
      data.activeTab = newValue;
    }); // Chuyển tab

    var switchTab = function switchTab(activeTab) {
      data.activeTab = activeTab;
      emit('onActiveTabChange', activeTab);
    };

    return {
      // Component reactive data
      data: data,
      // Enum
      InvestorDetailPageSection: InvestorDetailPageSection,
      // Functions
      formatNumber: formatNumber,
      getTimeSince: getTimeSince,
      encapsulateUrl: encapsulateUrl,
      switchTab: switchTab
    };
  }
});