import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.object.to-string.js";
import { useInit, convertGetMasterData } from '@/services/shared/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue3';
import { defineComponent, reactive, ref, watch } from 'vue';
import HistoryCallLeadSkeleton from '../../skeleton/lead/HistoryCallLeadSkeleton.vue';
import AppGridRender from '../../ui/AppGridRender.vue';
import { optionReturnEnum } from '@/services/features/lead/lead.model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
ModuleRegistry.registerModules([ClientSideRowModelModule]); // Định nghĩa data type của component [props] bằng Typescript

var HistoryCallComponentProps = function HistoryCallComponentProps() {
  _classCallCheck(this, HistoryCallComponentProps);
};

var HistoryCallComponentData = function HistoryCallComponentData() {
  _classCallCheck(this, HistoryCallComponentData);
};

export default defineComponent({
  name: 'HistoryCallComponent',
  components: {
    AgGridVue: AgGridVue,
    HistoryCallLeadSkeleton: HistoryCallLeadSkeleton
  },
  props: {
    callLog: {
      type: Array,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var any = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base,
        isLoggedIn = _useInit.isLoggedIn;

    var data = reactive({
      callLog: props.callLog,
      isLoading: props.isLoading,
      rowData: []
    });

    var isNotReasonOption = function isNotReasonOption(rejectReason) {
      return optionReturnEnum.some(function (item) {
        return item.key === rejectReason;
      });
    }; // Setting Ag-Grid


    var gridApi = ref();

    var onGridReady = function onGridReady(params) {
      gridApi.value = params.api;
    };

    var columnDefs = reactive({
      value: [{
        headerName: 'STT',
        field: 'stt',
        width: 80
      }, {
        headerName: 'Số điện thoại gọi',
        field: 'from.number',
        width: 200
      }, {
        headerName: 'Mã khách hàng',
        field: 'to.agentInfo.code',
        width: 180
      }, {
        headerName: 'Tên khách hàng',
        field: 'to.agentInfo.name',
        width: 250
      }, {
        headerName: 'SĐT khách hàng',
        field: 'to.agentInfo.phone',
        width: 200
      }, {
        headerName: 'Ghi chú',
        field: 'note',
        width: 350
      }, {
        headerName: 'Lý do trả về',
        width: 350,
        valueGetter: function valueGetter(params) {
          return params.data.rejectReason ? isNotReasonOption(params.data.rejectReason) ? convertGetMasterData(optionReturnEnum, params.data.rejectReason) : params.data.rejectReason : '';
        }
      }, {
        headerName: 'Gọi lại',
        width: 165,
        cellRenderer: AppGridRender
      }]
    });
    var gridOptions = {
      defaultColDef: {
        resizable: true,
        autoHeight: true,
        tooltipComponent: 'customTooltip',
        sortable: false,
        enableCellChangeFlash: false
      },
      getRowHeight: function getRowHeight() {
        return 50;
      },
      autoSizePadding: 10,
      cellFlashDelay: 500,
      rowBuffer: 30,
      animateRows: true,
      suppressRowTransform: true,
      enableCellTextSelection: true,
      singleClickEdit: true,
      enableBrowserTooltips: true,
      tooltipShowDelay: 1,
      overlayNoRowsTemplate: '<span> Không có dữ liệu </span>'
    };
    watch(function () {
      return props.callLog;
    }, function () {
      data.callLog = props.callLog;
      data.rowData = props.callLog;
    });
    watch(function () {
      return props.isLoading;
    }, function () {
      data.isLoading = props.isLoading;
    });
    return {
      isLoggedIn: isLoggedIn,
      data: data,
      onGridReady: onGridReady,
      columnDefs: columnDefs,
      gridOptions: gridOptions,
      isNotReasonOption: isNotReasonOption
    };
  }
});