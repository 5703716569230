import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/icons/simple/icon_vector.svg';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0739c850"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "post-user-in-group-component"
};
var _hoisted_2 = {
  class: "d-flex flex-column"
};
var _hoisted_3 = {
  class: "post-user-in-group-component__title-container"
};
var _hoisted_4 = {
  key: 0,
  src: _imports_0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;

  var _component_UserAvatarComponent = _resolveComponent("UserAvatarComponent");

  var _component_UserDetailDialog = _resolveComponent("UserDetailDialog");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["post-user-in-group-component__avatar", {
      large: _ctx.large,
      medium: !_ctx.large
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.handleDialogUserVisible(true);
    })
  }, [_createVNode(_component_UserAvatarComponent, {
    currentUser: _ctx.user
  }, null, 8
  /* PROPS */
  , ["currentUser"])], 2
  /* CLASS */
  ), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("a", {
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.handleDialogUserVisible(true);
    }),
    href: "javascript:;",
    class: "post-user-in-group-component__title"
  }, _toDisplayString((_a = _ctx.user) === null || _a === void 0 ? void 0 : _a.name), 1
  /* TEXT */
  ), _ctx.group ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("v-if", true), _ctx.group ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: "javascript:;",
    class: "post-user-in-group-component__title",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.goToGroupDetailPage(_ctx.group.id);
    })
  }, _toDisplayString((_b = _ctx.group) === null || _b === void 0 ? void 0 : _b.name), 1
  /* TEXT */
  )) : _createCommentVNode("v-if", true)])]), _createVNode(_component_UserDetailDialog, {
    modelValue: _ctx.data.dialogUserDetail.model,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.data.dialogUserDetail.model = $event;
    }),
    visible: _ctx.data.dialogUserDetail.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.data.dialogUserDetail.visible = $event;
    })
  }, null, 8
  /* PROPS */
  , ["modelValue", "visible"])]);
}