import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_LeadFormComponent = _resolveComponent("LeadFormComponent");

  return _ctx.data.lead && _ctx.data.masterData ? (_openBlock(), _createBlock(_component_LeadFormComponent, {
    key: 0,
    modelValue: _ctx.data.lead,
    masterData: _ctx.data.masterData
  }, null, 8
  /* PROPS */
  , ["modelValue", "masterData"])) : _createCommentVNode("v-if", true);
}