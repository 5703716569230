import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import UserDetailDialog from '@/components/features/user-profile/UserDetailDialog.vue';
import UserAvatarComponent from '@/components/features/user-profile/UserAvatarComponent.vue';

var PostUserInGroupComponentProps = function PostUserInGroupComponentProps() {
  _classCallCheck(this, PostUserInGroupComponentProps);
};

var PostUserInGroupComponentData = function PostUserInGroupComponentData() {
  _classCallCheck(this, PostUserInGroupComponentData);
};

export default defineComponent({
  name: 'PostUserInGroupComponent',
  components: {
    UserDetailDialog: UserDetailDialog,
    UserAvatarComponent: UserAvatarComponent
  },
  props: {
    group: {
      type: Object,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  emit: ['goToGroup'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base,
        currentUser = _useInit.currentUser;

    var data = reactive({
      dialogUserDetail: {
        model: {},
        visible: false
      }
    });
    var user = currentUser;

    var handleDialogUserVisible = function handleDialogUserVisible(isVisible) {
      data.dialogUserDetail.model = user.value;
      data.dialogUserDetail.visible = isVisible;
    };

    var goToGroupDetailPage = function goToGroupDetailPage(id) {
      base.router.push({
        name: 'group-detail',
        params: {
          id: id
        }
      });
      emit('goToGroup', id);
    };

    return {
      data: data,
      user: user,
      handleDialogUserVisible: handleDialogUserVisible,
      goToGroupDetailPage: goToGroupDetailPage
    };
  }
});