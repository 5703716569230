import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { isEmpty } from 'lodash';
import PostPreviewMediaComponent from '@/components/features/group/PostPreviewMediaComponent.vue';

var PostCreatorMediaLayoutProps = function PostCreatorMediaLayoutProps() {
  _classCallCheck(this, PostCreatorMediaLayoutProps);
};

var PostCreatorMediaLayoutData = function PostCreatorMediaLayoutData() {
  _classCallCheck(this, PostCreatorMediaLayoutData);
};

export default defineComponent({
  name: 'PostCreatorMediaLayout',
  components: {
    PostPreviewMediaComponent: PostPreviewMediaComponent
  },
  props: {
    medias: {
      type: Array,
      default: null
    },
    documents: {
      type: Array,
      default: null
    }
  },
  emits: ['close', 'closeMedia', 'closeDocument'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      medias: props.medias,
      documents: props.documents
    });
    watch(function () {
      return props.medias;
    }, function () {
      return data.medias = props.medias;
    }, {
      deep: true
    });
    watch(function () {
      return props.documents;
    }, function () {
      return data.documents = props.documents;
    }, {
      deep: true
    });

    var closeIcon = function closeIcon(index) {
      emit('closeMedia', index);
    };

    var closeDocument = function closeDocument(index) {
      emit('closeDocument', index);
    };

    return {
      data: data,
      isEmpty: isEmpty,
      closeIcon: closeIcon,
      closeDocument: closeDocument
    };
  }
});