import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, reactive, watch } from 'vue';
import { encapsulateUrl, formatNumber, openNewTab, useInit } from '@/services/shared/utils';
import { ProjectModel, ProjectStatusEnum } from '@/services/features/property/property.model';
import { getProjectStatus, getProjectType } from '@/services/features/property/property.service';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'; // Định nghĩa data type của component [data] bằng Typescript

var ProjectListItemComponentData = function ProjectListItemComponentData() {
  _classCallCheck(this, ProjectListItemComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var ProjectListItemComponentProps = function ProjectListItemComponentProps() {
  _classCallCheck(this, ProjectListItemComponentProps);
};

export default defineComponent({
  name: 'ProjectListItemComponent',
  components: {
    Popover: Popover,
    PopoverButton: PopoverButton,
    PopoverPanel: PopoverPanel
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['onOpenDialog'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      project: props.project || new ProjectModel()
    });
    watch(function () {
      return props.project;
    }, function (newValue, oldValue) {
      data.project = newValue || new ProjectModel();
    });

    var getPriceText = function getPriceText() {
      if (!data.project) {
        return '';
      }

      var text = '';

      if (data.project.priceFrom && data.project.priceTo) {
        if (data.project.priceFrom === data.project.priceTo) {
          text = "".concat(formatNumber(data.project.priceFrom), "tr/m2");
        } else {
          text = "".concat(formatNumber(data.project.priceFrom), "-").concat(formatNumber(data.project.priceTo), "tr/m2");
        }
      } else if (data.project.priceFrom) {
        text = "".concat(formatNumber(data.project.priceFrom), "tr/m2");
      } else if (data.project.priceTo) {
        text = "".concat(formatNumber(data.project.priceTo), "tr/m2");
      }

      return text;
    };

    var getCommissionText = function getCommissionText() {
      if (!data.project) {
        return '';
      }

      var text = '';

      if (data.project.minComm && data.project.maxComm) {
        if (data.project.minComm === data.project.maxComm) {
          text = "".concat(data.project.minComm, "%");
        } else {
          text = "".concat(data.project.minComm, "-").concat(data.project.maxComm, "%");
        }
      } else if (data.project.minComm) {
        text = "".concat(data.project.minComm, "%");
      } else if (data.project.maxComm) {
        text = "".concat(data.project.maxComm, "%");
      }

      return text;
    };

    var isSaleComing = function isSaleComing(status) {
      return status === ProjectStatusEnum.COMING_SALE;
    };

    var goToGroupDetailPage = function goToGroupDetailPage(id) {
      return base.router.push({
        name: 'group-detail',
        params: {
          id: id
        }
      });
    };

    var handleOrder = function handleOrder() {
      emit('onOpenDialog');
    };

    var handleBuy = function handleBuy() {
      emit('onOpenDialog');
    };

    var isShowBtnDocs = function isShowBtnDocs(project) {
      return project.url360 || project.url3D || project.floorMap;
    };

    return {
      data: data,
      formatNumber: formatNumber,
      encapsulateUrl: encapsulateUrl,
      openNewTab: openNewTab,
      getPriceText: getPriceText,
      getCommissionText: getCommissionText,
      getProjectType: getProjectType,
      getProjectStatus: getProjectStatus,
      isSaleComing: isSaleComing,
      goToGroupDetailPage: goToGroupDetailPage,
      ProjectStatusEnum: ProjectStatusEnum,
      handleOrder: handleOrder,
      handleBuy: handleBuy,
      isShowBtnDocs: isShowBtnDocs
    };
  }
});